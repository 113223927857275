.checklist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.checklist.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.checklist > .card {
  width: 100%;
  cursor: pointer;
}

.checklist > .card:hover {
  --bs-card-bg: var(--color-brand-main-0);
}

.checklist > .card.selected {
  --bs-card-border-color: var(--color-brand-main-700);
  --bs-card-bg: var(--color-brand-main-100);
  color: var(--color-brand-main-700);
}
