#parental-consent.modal {
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
}

#parental-consent .modal-content {
  border-radius: 16px 16px 28px 16px;
  background: #FFE7CF;
  box-shadow: 0px 10px 20px 0px rgba(26, 16, 50, 0.20);
}

#parental-consent .modal-header {
  font-weight: 500;
  font-size: 20px;
}

#parental-consent .btn-close {
  background: transparent var(--bs-btn-close-bg) center/0.8rem auto no-repeat;
  box-shadow: unset;
}

#parental-consent .btn-agree {
  width: 350px;
  max-width: 100%;
}