.recorderActive,
.recorderInactive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 42px;
}

.control {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: var(--secondary-orange-base-500);
  transition: box-shadow 0.5s ease-in-out;
}

.recorderActive > .control,
.control:hover {
  background-color: var(--secondary-orange-base-700);
}

.visualize {
  box-shadow: 0 0 0 12px #d9d9d9, 0 0 0 24px rgba(217, 217, 217, 0.32);
}

.message {
  color: var(--color-brand-neutral-700);
  font-weight: 500;
  line-height: 24px;
  margin: 24px 0 8px 0;
  text-align: center;
}

.timer {
  min-height: 24px;
}
