
.er-quiz-wrapper {
  background-image: none;
}

.er-quiz-result > .content > .result-wrapper > .title {
  color: #513D62;
}

.er-quiz-result > .content > .result-wrapper > .subtitle {
  color: var(--bs-body-color);
}

.er-quiz-result > .content > .result-wrapper {
  margin: 0 16px 20%;
}

@media (max-width: 1199.99px) {
  .er-quiz-result > .content > .result-wrapper {
    margin: 64px 16px 32px;
  }
}