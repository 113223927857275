.sp-quiz-result {
  color: #FFE7CF;
}


.sp-quiz-result > .content > .result-wrapper > .title {
  color: #C3AEF0;
}

@media (max-width: 1199.99px) {
  .sp-quiz-result {
    background-position-y: -100px;
  }
}