#couple-disclaimer.modal {
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
}

#couple-disclaimer .modal-content {
  border-radius: 16px 16px 28px 16px;
  background: #FFE7CF;
  box-shadow: 0px 10px 20px 0px rgba(26, 16, 50, 0.20);
}

#couple-disclaimer .modal-header {
  font-weight: 500;
  font-size: 20px;
}

#couple-disclaimer .btn-close {
  background: transparent var(--bs-btn-close-bg) center/0.8rem auto no-repeat;
  box-shadow: unset;
}

#couple-disclaimer .btn-agree {
  width: 260px;
  max-width: 100%;
}

#couple-disclaimer a {
  color: var(--primary-purple-dark-600);
}

@media (max-width: 991.98px) {
  #couple-disclaimer .btn-agree {
    width: 100%;
    margin-top: 24px;
  }
}