.quiz-results {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 155vw;
  min-height: calc(100vh - 247px);
  z-index: 0;
}

.quiz-results > .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 138px;
  padding: 1rem;
  max-width: 100%;
  z-index: 1;
}

.quiz-results > .content > .result-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  line-height: normal;
  width: 480px;
  max-width: 100%;
  margin: auto 16px;
}

.quiz-results > .content > .result-wrapper > .subtitle {
  font-family: "Family";
  font-size: 48px;
  font-weight: 700;
}

.quiz-results > .content > .result-wrapper > .title {
  font-size: 76px;
  font-family: "Family";
  font-weight: 800;
  line-height: 1.4;
}

.quiz-results > .content > .result-wrapper > .description {
  font-size: 18px;
}

.quiz-results > .content > .send-report-wrapper {
  display: flex;
  max-width: 100%;
}

@media (max-width: 1199.99px) {
  .quiz-results {
    background-size: auto;
  }

  .quiz-results> .content > .result-wrapper {
    margin: 64px 16px 32px;
  }
  
  .quiz-results > .content > .result-wrapper > .title {
    font-size: 56px;
  }

  .quiz-results > .content > .result-wrapper > .subtitle {
    font-size: 38px;
  }

  .quiz-results > .content {
    gap: 20px;
  }
}