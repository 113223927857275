.footer {
  width: 100%;
  background-color: var(--primary-purple-dark-600);
  z-index: 1;
  color: #FFF;
  position: relative;
  font-size: 14px;
}

.footer p {
  font-size: 14px;
}

p.link{
  text-decoration: underline;
  cursor: pointer;
}

.link {
  color: #FFF;
}

.disclaimer {
  opacity: 0.5;
  margin-top: 40px;
}