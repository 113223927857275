.upgrade-step > .head {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color-brand-main-700);
  color: var(--color-brand-main-0);
  padding: 0 24px;
}

.upgrade-step > .head > .cover {
  width: 408px;
  max-width: 100%;
}

.upgrade-step > .head > .content > .title,
.upgrade-step > .body > .title {
  font-family: "Family";
  font-size: 38px;
  font-weight: 700;
}

.upgrade-step > .body > .title {
  margin-bottom: 24px;
  line-height: 100%;
}

.upgrade-step > .head > .content > ul > li {
  line-height: 16px;
  margin-bottom: 8px;
}

.upgrade-step > .body {
  padding: 24px;
}

.upgrade-step > .body > .disclaimer {
  padding: 16px;
  background-color: #FBE9D0;
  border: 1px solid var(--color-brand-neutral-300);
  border-radius: 16px;
}

.upgrade-step > .foot {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.upgrade-step > .foot > button {
  max-width: 100%;
}