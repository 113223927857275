
.rom-partners-result {
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 247px);
  overflow: auto;
}

.rom-partners-result > .left-panel,
.rom-partners-result > .right-panel {
  padding: 64px;
  min-width: 50%;
  display: flex;
  flex-direction: column;
}

.rom-partners-result > .left-panel {
  gap: 32px;
}

.rom-partners-result > .right-panel {
  background-color: var(--color-brand-main-700);
  gap: 16px;
}

.rom-partners-result > .left-panel .title {
  color: #23262A;
  font-family: Family;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.rom-partners-result > .left-panel .description {
  color: #23262A;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.18px;
}

.rom-partners-result > .right-panel .result-badge {
  border-radius: 8px;
  background:  #E7716D;
  color: #FFF;
  padding: 4px 8px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.22px;
  line-height: 150%;
  margin-bottom: 12px;
  display: inline-block;
}

.rom-partners-result > .right-panel .title {
  font-family: 'Family';
  font-size: 28px;
  color: #EEEFF0;
  font-weight: 700;
  line-height: 40px;
}

.rom-partners-result .diagram {
  width: 420px;
  max-width: 100%;
}

.rom-partners-result .partner-card {
  display: flex;
  align-items: center;
  gap: 10;
  border-radius: 16px;
  border: 1px solid  #CA6C0A;
  background: #FFE7CF;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 8px;
}

.partner-card > .bullet {
  border-radius: 50%;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}


.rom-partners-result .result-label {
  color:  #FFF;
  font-family: Family;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width: 991.98px) {
  .rom-partners-result {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .rom-partners-result > .left-panel,
  .rom-partners-result > .right-panel {
    padding: 48px 24px;
    min-width: 100%;
  }
}