.quiz-result {
  position: relative;
  height: 100%;
  z-index: 0;
}

.quiz-result > .background {
  position: absolute;
  top: -390px;
  left: -150px;
}

.quiz-result > .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  height: 100%;
  z-index: 1;
  position: relative;
  gap: 100px;
}

.quiz-result > .content > .result-wrapper {
  width: 420px;
  max-width: 100%;
  margin: 140px 16px;
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.quiz-result .subtitle {
  color: var(--color-brand-neutral-700);
}

.quiz-result > .content > .result-wrapper > .title {
  font-size: 76px;
  font-family: 'Family';
  font-weight: 800;
  line-height: 84px;
}

.quiz-result > .content > .send-report-wrapper {
  display: flex;
  max-width: 100%;
}

.quiz-result .description {
  font-size: 18px;
}

@media (max-width: 991.98px) {
  .quiz-result > .content > .result-wrapper > .title {
    font-size: 56px;
  }

  .quiz-result > .content > .result-wrapper {
    margin: 64px 16px;
  }
}

@media (max-width: 575.98px) {
  .quiz-result > .content {
    gap: 20px;
  }

  .quiz-result > .background {
    top: 327px;
    right: -450px;
    left: unset;
  }
}
