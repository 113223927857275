.workplace {
  position: relative;
  height: 100%;
  z-index: 0;
  max-width: 1440px;
  margin: auto;
}

.workplace > .background {
  position: absolute;
  top: -370px;
  left: -150px;
  width: auto;
}

.workplace > .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  height: 100%;
  z-index: 1;
  position: relative;
  gap: 100px;
}

.workplace .send-report {
  padding: 2rem;
}

.workplace > .content > .result-wrapper {
  width: 420px;
  max-width: 100%;
  margin: 200px 16px;
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.workplace > .content > .result-wrapper .send-report {
    width: 630px;
}

.workplace .send-report .header .title {
  margin-top: 20px;
}

.workplace .send-report-form .btn-primary {
  background-color: #002859;
}

.workplace .subtitle {
  color: var(--color-brand-neutral-700, #788087);
}

.workplace > .content > .result-wrapper > .title {
  font-size: 66px;
  font-family: "Family";
  font-weight: 800;
  line-height: 84px;
}

.workplace > .content > .send-report-wrapper {
  display: flex;
  max-width: 100%;
}

.workplace .description {
  font-size: 18px;
}

@media (max-width: 1200px){
  .workplace > .content {
    gap: 30px;
  }

  .workplace > .content > .result-wrapper > .title {
    font-size: 56px;
  }
}

@media (max-width: 991.98px) {
  .workplace > .content {
    gap: 20px;
  }

  .workplace > .content> .result-wrapper {
    margin: 40px 16px;
  }
}

@media (max-width: 575.98px) {
  .workplace > .content > .result-wrapper > .title {
    font-size: 40px;
  }

  .workplace .send-report {
    padding: 2rem 1.3rem;
  }

  .workplace .result-wrapper .description {
    font-size: 16px;
  }

  .workplace > .background {
    top: 327px;
    right: -450px;
    left: unset;
  }
}