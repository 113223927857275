header.header {
  position: relative;
  background-color: #FFF;
  z-index: 1;
}

header.header .title {
  font-size: 16px;
  font-weight: 700;
  text-align: end;
  margin: 0 0 0 auto;
}