:root {
  --color-danger: #e02f49;
  --color-success: #0d7c7b;
  --color-warning: #f29c43;

  --color-brand-neutral-100: #e0e1e3;
  --color-brand-neutral-300: #d1d2d4;
  --color-brand-neutral-700: #788087;
  --color-brand-neutral-900: #333840;
  --color-brand-main-0: #f9f5ff;
  --color-brand-main-100: #eae2f9;
  --color-brand-main-700: #604c8d;
  --color-brand-neutral-900: #333840;
  --color-attachment-anxious-300: #ffe7cf;
  --color-background-base: #fdf6ec;

  --primary-purple-dark-600: #2c2046;
  --secondary-orange-base-400: #ebb549;
  --secondary-orange-base-500: #f1ad28;
  --secondary-orange-base-700: #d3810e;

  --toastify-color-success: var(--color-success);
  --toastify-color-warning: var(--color-warning);
  --toastify-color-error: var(--color-danger);

  --toastify-toast-width: 440px;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  /* ExtraBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Family';
  src: url('../fonts/Family-Heavy.woff2') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Family';
  src: url('../fonts/Family-Bold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  color: var(--color-brand-neutral-900);
  background-color: #fdfdff;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.mw-260 {
  max-width: 260px !important;
}

.mw-420 {
  max-width: 420px !important;
}
