.send-report {
  max-width: 100%;
  width: 646px;
  padding: 1.375rem;
  border: 1px solid #BCBCBC;
  border-radius: 2rem;
  background-color: var(--color-attachment-anxious-300);
  color: var(--bs-body-color);
}

.send-report > .header > .cover-image {
  width: 400px;
  max-width: 100%;
}

.send-report > .header > .title {
  line-height: 2.25rem;
}