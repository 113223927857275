
.er-quiz-wrapper {
  background-image: url('../../../../assets/images/flower-field-girl.png');
  background-position: bottom -72px left;
  background-repeat: no-repeat;
  background-size: auto 440px;
}

.er-quiz-result > .content > .result-wrapper > .title {
  color: #F29C43;
}

.er-quiz-result > .content > .result-wrapper > .subtitle {
  color: var(--color-brand-main-700);
}

.er-quiz-result > .content > .result-wrapper {
  margin: 0 16px 20%;
}

@media (max-width: 1199.99px) {
  .er-quiz-result > .content > .result-wrapper {
    margin: 64px 16px 32px;
  }
}