.home-page {
  font-family: "Family", sans-serif;
  text-align: center;
  padding: 16px 0;
}

.home-page .title {
  color: rgb(var(--bs-primary-rgb));
  margin-bottom: 48px;
  font-size: 32px;
}

.attachment-wrapper {
  margin: auto;
}

@media (min-width: 576px) {
  .home-page .start-quiz-btn {
      margin-top: 88px;
  }

  .attachment-wrapper {
    width: 130px;
    height: 130px;
  }
}

@media (min-width: 768px) {
  .attachment-wrapper {
    width: 155px;
    height: 155px;
  }
}

@media (min-width: 992px) {
  .attachment-wrapper {
    width: 210px;
    height: 210px;
  }

  p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .home-page {
    padding: 48px 0;
  }

  .home-page .title {
    font-size: 48px;
    margin-bottom: 64px;
  }

  .home-page .attachment-wrapper {
    width: 240px;
    height: 240px;
  }
}