.self-esteem {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: "Lexend";
  min-height: 100vh;
}

.self-esteem > .cover {
  text-align: center;
}

.self-esteem > .cover > img {
  width: 420px;
  max-width: 100%;
  height: auto;
}

.self-esteem > .title {
  font-size: 28px;
  font-weight: 500;
}

.self-esteem > .action {
  max-width: unset;
}