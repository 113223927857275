.sp-quiz-result {
  color: #FFF3F7;
}


.sp-quiz-result > .content > .result-wrapper > .title {
  color: #FFE7CF;
}
.sp-quiz-result {
  background-position-x: -50px;
}

@media (max-width: 1199.99px) {
  .sp-quiz-result {
    background-position-y: -100px;
    background-position-x: 0;
  }
}

@media (min-width: 600px) and (max-width:1199.99px) {
  .sp-quiz-result {
    background-size: 250%;
  }
}

@media (max-width: 600px){
  .quiz-results> .content > .result-wrapper {
    margin: 30px 16px 20px;
  }
}