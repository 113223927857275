.quiz-survey {
  opacity: 1;
  transition: opacity 1s;
  will-change: opacity;
}

.quiz-survey.loading {
  opacity: 0;
  transition: opacity 0s;
}

.quiz-survey > .header .image {
  width: 120px;
  margin-right: 24px;
}

.quiz-survey > .header .title {
  font-weight: 700;
  flex-basis: calc(100% - 150px);
  flex-grow: 1;
}

.quiz-survey .question-container {
  padding: 0 10%;
}

.quiz-survey .validation-error {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  padding: 16px;
}

.quiz-survey > .action-wrapper {
  margin: 32px 0;
  padding-top: 24px;
}

@media (max-width: 575.98px) {
  .quiz-survey > .header .image {
    width: 96px;
  }

  .quiz-survey > .header .title {
    flex-basis: calc(100% - 120px);
  }

  .quiz-survey .question-container {
    padding: 0;
  }
}
