.attachment-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100px;
  height: 100px;
}

.attachment-wrapper > picture > img {
  object-fit: cover;
  object-position: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  max-width: 100%;
}

.attachment-wrapper > .shape {
  position: absolute;
  right: -16.66%;
  bottom: 12.5%;
  width: 33%;
  height: 33%;
  min-width: 40px;
  min-height: 40px;
}