#upgrade-couple-report .modal-content {
  background-color: var(--color-background-base);
  border-color: transparent;
  overflow: hidden;
}

#upgrade-couple-report .modal-body {
  padding: 0;
}

@media (min-width: 992px) {
  #upgrade-couple-report>.modal-dialog {
    --bs-modal-width: 863px;
  }
}