.footer {
  position: relative;
  width: 100%;
  height: 120px;
}

.footer .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.footer a {
  font-weight: bold;
  color: #FFFFFF;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .content {
  position: absolute;
  bottom: 11px;
  left: 0;
  margin: auto;
  right: 0;
}

.footer .background {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  min-height: 120px;
}

@media (max-width: 575.98px) {
  .footer .container {
    padding: 8px;
  }
} 

@media (max-width: 380px) {
  .footer .container {
    padding: 2px;
  }
}